.release-page {
  padding: 24px 0;

  display: flex;
  flex-flow: column nowrap;
}

.release-page-content {
  flex-grow: 1;
  min-height: 200px;
}

.release-page-footer {
  flex-shrink: 0;

  display: flex;
  justify-content: center;
}

.release-title {
  font-size: 42px;

  padding-bottom: 12px;
  margin-bottom: 20px;

  border-bottom: 2px solid black;
}

.release-item {
  margin-bottom: 8px;
}

.release-blocks {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

.release-empty-state {
  display: flex;
  flex-flow: column;
  align-items: flex-start;

  font-size: 24px;
}

.release-empty-explore {
  align-self: center;

  display: flex;
  flex-flow: column;
  align-items: center;

  font-size: 24px;
  padding: 24px;

  background-color: #fea3aa80;
  transition: box-shadow 400ms ease-out;

  margin: 48px 0;
}

.release-empty-explore:hover,
.release-empty-explore:focus {
  box-shadow: 0px 0px 10px 1px #ccc;
}

.release-end-button {
  align-self: center;

  display: flex;
  flex-flow: column;
  align-items: center;

  font-size: 24px;

  margin: 36px 0;
}

@media all and (max-width: 768px) {
  .release-end-button {

    display: flex;
    flex-flow: column;
    align-items: flex-start;

  }

}
