.home-definition-items > div {
  margin: 12px 0px;

  transition: all 300ms ease;
}

.lexiconomy-title {
  font-size: 75px;
}

.lexiconomy-pronounciation {
  font-size: 20px;
}

.lexiconomy-part-of-speech {
  font-style: italic;
  font-size: 20px;
}

.lexiconomy-definition {
  font-weight: lighter;
  font-size: 24px;
}

@media (max-width: 600px) {
  .lexiconomy-title {
    font-size: 54px;
  }

  .lexiconomy-pronounciation {
    font-size: 16px;
  }

  .lexiconomy-part-of-speech {
    font-size: 16px;
  }

  .lexiconomy-definition {
    font-size: 18px;
  }
}
