.composeModalText,
.composeModalText > div {
  max-width: 400px;
  line-height: 1.5;
}

.composeModalText > div {
  margin-bottom: 12px;
}

.compositonModalMoreInfo {
  color: #3a6992;
}
