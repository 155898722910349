.Home {
  margin-top: 128px;
  text-align: center;
}

.Home-banner {
  display: flex;
  flex-flow: column nowrap;
  align-content: flex-start;
  align-items: center;
  justify-content: flex-start;
}

.home-search {
  width: 80%;
}
