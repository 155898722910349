.manifestoPage {
  display: flex;
  flex-flow: column nowrap;

  max-width: 800px;
}

.manifestoTitle {
  font-size: 42px;
  margin: 24px 0;
}

.manifestoSectionDetails {
  font-size: 20px;
  line-height: 1.7;
}

.manifestoLink {
  color: #3a6992;
}

.manifestoLink:hover {
  text-decoration: underline;
}
