.nav-bar {
  border: 1px solid black;
  border-left: 0px solid black;
  border-right: 0px solid black;
  padding: 4px 24px 4px 24px;
  transition: all 500ms ease;
}

.nav-bar-container {
  height: 64px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: center;
}

.nav-bar-mobile-container {
  height: 128px;
  display: none;
}

.nav-bar-container > a {
  color: black;
  text-decoration: none;
}

.nav-item > a:hover {
  color: #3a6992;
}

.nav-title {
  font-size: 24px;
  margin-right: 24px;
}

.nav-title > a:hover {
  color: #3a6992;
}

.nav-item {
  font-size: 20px;
  margin-left: 12px;
}

.nav-spacer {
  margin-left: auto;
}

.nav-item:after {
  content: " | ";
}

.nav-item:last-child:after {
  content: " ";
}

.nav-search {
  flex-grow: 1;
  transition: all 500ms ease;
  padding-right: 24px;
  display: flex;

  outline: none;
}

.nav-search-icon {
  padding-top: 8px;
  padding-right: 12px;
}

.nav-search-bar {
  flex: 0;
  transition: all 500ms ease-out 500ms;
  padding-right: 24px;
  visibility: hidden;
  width: 0%;
}

.nav-search-bar.search-visible {
  width: 100%;
  visibility: visible;
  flex: 1;

  transition: all 200ms ease-out 0s;
}
.nav-mobile-menu-icon {
  display: none;
  margin-right: 24px;
}

.nav-mobile-menu {
  display: flex;
  flex-flow: column;

  height: 0px;
  visibility: hidden;
  opacity: 0;

  transition: height 500ms ease-out 0s, opacity 300ms ease-out 0s;
}

@media all and (max-width: 850px) {
  .nav-item {
    font-size: 18px;
    margin-left: 4px;
  }
}

@media all and (max-width: 768px) {
  .nav-bar {
    padding: 4px 12px 8px 12px;
  }

  .nav-bar-expanded > .nav-mobile-menu {
    height: 200px;
    visibility: visible;
    opacity: 1;
  }

  .nav-mobile-menu > a {
    font-size: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .nav-mobile-menu > a:hover {
    background-color: #80808033;
  }

  .nav-title {
    display: none;
  }

  .nav-item {
    display: none;
  }

  .nav-search-icon {
    padding-top: 4px;
  }

  .nav-mobile-menu-icon {
    display: inherit;
  }
}
