.footer {
  flex-shrink: 0;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  padding: 12px 24px;

  border-bottom: 1px solid black;
}

.footer-left-align {
  flex-grow: 1;
}

.footer-right-align {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}
