.lexiconomy-button {
  display: flex;
  justify-content: center;

  min-height: 48px;
  min-width: 150px;

  background: #353535db;

  color: white;
  font-size: 18px;
  font-family: monospace;

  box-sizing: border-box;

  border-style: solid;
  border-color: #353535db;
  border-radius: 3px;
  border-width: 1px;

  transition: all 300ms ease;

  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);

  padding: 8px;
}

.lexiconomy-button:hover {
  background: #353535;
}

.lexiconomy-button-disabled {
  background: #73727263;
  border-color: #73727263;
}

.lexiconomy-button-disabled:hover {
  background: #73727263;
  cursor: not-allowed;
}
