.tipsPage {
  max-width: 800px;
}

.tipsSectionTitle {
  font-size: 42px;
  margin: 24px 0 48px 0;
}

.guideSectionTitle {
  font-size: 32px;
}

.guideSection {
  margin-bottom: 36px;
  padding-bottom: 36px;

  border-bottom: #e8e8e8 solid 2px;
}

.guideSectionDetails {
  font-size: 20px;
  line-height: 1.7;
}

.guideSectionSubHeader {
  font-size: 24px;
}

.guideLink {
  color: #3a6992;
}

.guideLink:hover {
  text-decoration: underline;
}

.cooldownTable {
  width: 80%;
  text-align: center;

  margin: 36px 0;
}

.cooldownTable > thead > tr > th,
.cooldownTable > tbody > tr:not(:last-child) > td {
  border-bottom: #a7a7a7 solid 1px;
  height: 36px;
}

.publicWordListTitle {
  font-size: 20px;
  color: #a7a7a7;

  margin: 24px 0;
}

.publicWordList {
  width: 80%;

  display: flex;
  flex-flow: row wrap;
}

.publicWordList > a {
  min-width: 50px;
  min-height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 18px;
  padding: 0 6px;
  font-size: 20px;
}

.compositionGraphic {
  width: 80%;
  margin: 36px 0;
}

.emailUs {
  color: #3a6992;
}

.compositionDescription {
  width: 80%;
  line-height: 1.7;

  font-size: 20px;
}
