.lemma-empty-state-container {
  display: flex;
  flex-flow: column nowrap;
  width: 80%;
  margin: 0 0 24px 0;
}

.lemma-empty-state-description {
  font-size: 24px;
}

.lemma-empty-state-celebration {
  font-size: 32px;
  margin: 24px 0px 36px 0px;
}

.lemma-empty-state-explanation {
  font-size: 24px;
  font-weight: lighter;
}

.lemma-name {
  font-weight: bold;
}

.lemma-empty-state-composition-explained,
.lemma-empty-state-or-compose {
  margin-top: 24px;
}
