.lemma-title {
  font-size: 72px;
  word-break: break-word;
  padding: 12px 0px;

  border-bottom: 4px solid black;
}

.lemma-page-container {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-content: center;

  padding: 24px 0;
}

.lemma-actions-container {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  margin: 24px 0;
}

.lemma-actions-container > button {
  margin-right: 18px;
}

@media (max-width: 768px) {
  .lemma-actions-container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .lemma-actions-container > .lexiconomy-button {
    margin: 12px;
  }
}
