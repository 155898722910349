body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

html,
body {
  height: 100%;
  font-family: "Montserrat", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: black;
}

* {
  text-transform: lowercase;
  line-height: 1.3;
  word-wrap: break-word;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */

button,
input {
  /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select {
  /* 1 */
  text-transform: none;
}

button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.root {
  display: flex;
  min-height: 100vh;
  flex-flow: column nowrap;
}

.main-content {
  padding-top: 24px;
  flex-grow: 1;

  padding: 0 16.7%;
}

@media all and (max-width: 600px) {
  .main-content {
    padding: 0 7.5%;
  }
}

div::after .font-montserrat {
  font-family: "Montserrat", sans-serif;
}

.font-roboto {
  font-family: "Roboto", sans-serif;
}

.font-space-mono {
  font-family: "Space Mono", monospace;
}

.vh {
  visibility: hidden;
}

.remove {
  display: none;
}

.clickable {
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}
