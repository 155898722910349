.release-timer {
  padding: 8px;

  background: none;

  color: black;
  font-size: 18px;
  font-family: monospace;
  box-sizing: border-box;

  transition: background-color 200ms ease;
}

.release-timer:hover {
  background-color: #80808033;
}

@media all and (max-width: 600px) {
  .release-timer {
    visibility: hidden;
  }
}
