.datepickerWrap {
  height: 300px;
}

.datepickerWrap,
.datepickerWrap > .react-datepicker-wrapper,
.datepickerWrap
  > .react-datepicker-wrapper
  > .react-datepicker__input-container {
  width: 100%;
}

.datepickerWrap
  > .react-datepicker-popper
  > .react-datepicker
  > .react-datepicker__time-container {
  width: 100px;
}

.datepickerWrap
  > .react-datepicker-popper
  > .react-datepicker
  > .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 100px;
}

.date-error {
  font-size: 14px;
  color: #ff2625;
}
