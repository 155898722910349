.donate-button {
  padding: 8px;
  cursor: pointer;
  transition: background-color 200ms ease;
  font-family: monospace;
}

.donate-button-text {
  font-size: 18px;
}

.donate-button-emoji {
  font-size: larger;
}

.donate-button:hover {
  background-color: #80808033;
}

@media all and (max-width: 600px) {
  .donate-button {
    visibility: hidden;
  }
}
