.withdraw-button {
  padding: 8px;
  cursor: pointer;
  transition: background-color 200ms ease;
  font-family: monospace;

  margin: 0 18px;
}

.withdraw-button-text {
  font-size: 18px;
}

.withdraw-button-emoji {
  font-size: larger;
}

.withdraw-button:hover {
  background-color: #80808033;
}

@media all and (max-width: 600px) {
  .withdraw-button {
    visibility: hidden;
  }
}
