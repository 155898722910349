.pagination-container {
  margin: 24px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 18px;
}

.current-page {
  text-decoration: underline;
  color: #3a6992;
}

.page {
  margin: 0 8px;
}

.arrow-left {
  width: 0;
  height: 0;

  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;

  border-right: 7px solid black;

  margin-right: 8px;
}

.arrow-right {
  width: 0;
  height: 0;

  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;

  border-left: 7px solid black;

  margin-left: 8px;
}
