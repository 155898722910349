.lexiconomy-home-blocks {
  margin-top: 48px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.lexiconomy-home-block {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 20%;
  height: 150px;
  font-size: 20px;
  text-decoration: none;
  color: black;

  transition: background-color 200ms ease;
}

.lexiconomy-home-block:hover {
  background-color: #80808033;
}

@media all and (max-width: 800px) {
  .lexiconomy-home-blocks {
    flex-flow: column wrap;
  }

  .lexiconomy-home-block {
    height: 50px;
    width: 75%;

    font-size: 18px;
  }
}
