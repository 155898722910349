.lemma-data-container {
  display: flex;
  flex-flow: row wrap;
}

.lemma-data-section {
  padding: 8px;
  margin: 4px 32px 4px 0;
}

.lemma-data-section.link:hover {
  background-color: #80808033;
  transition: all 400ms ease;
}

.lemma-data-section.link:hover > .lemma-data-label {
  filter: brightness(0.2);
}

.lemma-data-value {
  font-size: 24px;
  white-space: pre-wrap;
}

.lemma-data-label {
  font-size: 18px;
  color: #636e72;
}

.lemma-data-stats {
  font-family: monospace;
  font-size: 18px;

  display: flex;
  flex-flow: row;
  align-items: center;
}

.lemma-data-stats > div:first-child {
  margin: 8px 8px 8px 0;
}

.lemma-data-stats > div:not(:first-child) {
  margin: 8px;
}

.lemma-description {
  font-size: 24px;

  display: flex;
  flex-flow: column;
}

.lemma-description-title {
  font-size: 32px;

  margin: 24px 0;
}

.lemma-description-explained {
  font-size: 24px;
}

.lemma-description-explained > a {
  text-decoration: underline;
  color: #3a6992;
}
