.card {
  display: flex;
  word-break: break-word;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: center;

  margin: 18px 18px 18px 0px;
  height: 128px;
  min-width: 128px;
  padding: 12px;
  transition: box-shadow ease 500ms;
}

.card:hover,
.card:focus {
  box-shadow: 0px 0px 10px 1px #ccc;
}

.card-title {
  font-size: 20px;
  font-weight: bold;
}

.card-details {
  font-size: 18px;
  font-weight: lighter;
  color: #36454f;
  margin: 4px 0px 4px 0px;
}
