.lemmaChartContainer {
  margin: 32px 0;
  padding: 12px 12px;

  display: flex;
  flex-flow: column nowrap;

  justify-content: center;
}

.lemmaChartDetails {
  font-size: 18px;

  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;

  margin: 12px 0;
  padding: 0 12px;
}

.lemmaChartTitle {
  font-size: 32px;

  white-space: pre;
  flex: 1;
}

.lemmaChartExtraDetails {
  display: flex;
  flex-flow: column nowrap;
}
.lemmaChartExtraDetails > div {
  margin: 4px 0;
}
