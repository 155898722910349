.common-loading-page {
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 50vh;
}

.spinner {
  animation: spin infinite 3s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
