.invalid-network-container {
  display: flex;
  flex-flow: column nowrap;
}

.invalid-network-title {
  font-size: 42px;
}

.invalid-network-description {
  font-size: 21px;
}
